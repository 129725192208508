import React, { useCallback, useContext, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { useDispatch, useSelector } from 'react-redux'
import { V3ClientTypes, TableTypes } from '@cango-app/types'
import { V3ProjectSdk } from '@cango-app/sdk'
import { Alert } from '@mui/material'
import AlertTitle from '@mui/material/AlertTitle'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { v4 } from 'uuid'
import _isEmpty from 'lodash/isEmpty'

import { selectors as projectSelectors } from 'src/store/modules/projects-v3/selectors'

import { Box, Button, Text } from '../components'
import { TableContext } from '../providers/table-provider'
import { applyFilterModelToRow } from '../modules/tables/utils'
import { colors } from '../theme/colors'
import { ChainList } from '../components/section-tasks-v3/chains-list'
import { actions as projectActions } from '../store/modules/projects-v3'
import { AsyncDispatchType } from '../store/types'
import { showSnackbar } from '../helpers/snackbarManager'

type AffectedDependency = {
	_id: string
	childStep: { _id: string; name: string }
	existingChain: V3ClientTypes.Project.Task['chain']
	selectedOption: string
	action: ProceedOption
	columnType: TableTypes.FieldType
}

type PromiseHandlers = {
	resolve: (value: SwitchChainResolveType | PromiseLike<SwitchChainResolveType>) => void
	newRow: GridValidRowModel
	oldRow: GridValidRowModel
	affectedDependencies: {
		_id: string
		chainSourceId: string
		dependencies: AffectedDependency[]
	}[]
}

export enum ConfirmationResolveType {
	Confirmed = 'confirmed',
	Rejected = 'rejected',
}

enum ProceedOption {
	AddToExisting = 'addToExisting',
	CreateNew = 'createNew',
}

export type SwitchChainResolveType = {
	state: ConfirmationResolveType
	dependencies: AffectedDependency[]
}

const useConfirmCellChange = (): [
	JSX.Element,
	(
		rowDifference: string[],
		newRow: GridValidRowModel,
		oldRow: GridValidRowModel,
	) => Promise<SwitchChainResolveType>,
] => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [promise, setPromise] = useState<PromiseHandlers | null>(null)
	const projectTasks = useSelector(projectSelectors.getProjectTasks)
	const { apiRef, mappedColumns } = useContext(TableContext)
	const [isLoading, setIsLoading] = useState(false)

	const confirm = (
		rowDifference: string[],
		newRow: GridValidRowModel,
		oldRow: GridValidRowModel,
	): Promise<SwitchChainResolveType> => {
		const allRowIds = apiRef.current.getAllRowIds()
		const allRows = allRowIds.map((id) => {
			if (id === newRow._id) return newRow
			return apiRef.current.getRow(id)
		})
		const columns = apiRef.current.getAllColumns()
		const changedDatabaseColumn = rowDifference[0]
		return new Promise((resolve) => {
			const affectedChildren: Map<
				// this is the task source id
				string,
				// these are a list of the affected children
				V3ClientTypes.Project.ProjectDescendant[]
			> = new Map()

			projectTasks.forEach((_task) => {
				_task.step?.descendants.forEach((_desc) => {
					const databaseChainLogic = _desc.database_chain_logic
					if (!databaseChainLogic) return
					const filteredRowsWithDatabaseLogic: GridValidRowModel[] = allRows.filter((_row) => {
						return applyFilterModelToRow(columns, _row, databaseChainLogic.filters)
					})
					const matchesFilters = filteredRowsWithDatabaseLogic.some(
						(_row) => _row._id === newRow._id,
					)
					if (!matchesFilters) return
					const existingAffectedDescendants = affectedChildren.get(_task._id) ?? []
					affectedChildren.set(_task._id, [...existingAffectedDescendants, _desc])
				})
			})

			if (!changedDatabaseColumn || !affectedChildren.size) {
				return resolve({ state: ConfirmationResolveType.Confirmed, dependencies: [] })
			}

			const content: PromiseHandlers['affectedDependencies'] = [...affectedChildren.entries()].map(
				([chainSourceId, _logic]) => {
					const dependencies: AffectedDependency[] = _logic.reduce(
						(_deps: AffectedDependency[], _child) => {
							const databaseLogic = _child.database_chain_logic
							if (!databaseLogic) {
								return _deps
							}
							const selectedOption = newRow[databaseLogic.column]
							const existingTaskInChain = projectTasks.find((_task) => {
								return (
									_task.chain?.original_descendant_id === _child._id &&
									_task.chain.label.selected_option === String(selectedOption)
								)
							})

							const columnType = mappedColumns.get(databaseLogic.column)
								?.type as TableTypes.FieldType

							_deps.push({
								_id: v4(),
								childStep: { _id: _child.step._id, name: _child.step.name },
								existingChain: existingTaskInChain?.chain,
								selectedOption,
								action: existingTaskInChain?.chain
									? ProceedOption.AddToExisting
									: ProceedOption.CreateNew,
								columnType,
							})
							return _deps
						},
						[],
					)

					return {
						_id: v4(),
						chainSourceId,
						dependencies,
					}
				},
			)

			setPromise({
				resolve,
				newRow,
				oldRow,
				affectedDependencies: content,
			})
		})
	}

	const handleClose = () => {
		setPromise(null)
	}

	const handleConfirm = useCallback(async () => {
		setIsLoading(true)
		try {
			const affectedDependencies = promise?.affectedDependencies ?? []
			const newChains = affectedDependencies.reduce(
				(
					_changes: {
						[sourceTaskId: string]: V3ProjectSdk.TaskDbChains
					},
					_dep,
				) => {
					_changes[_dep.chainSourceId] = _dep.dependencies.reduce(
						(_acc: V3ProjectSdk.TaskDbChains, _child) => {
							if (_child.action === ProceedOption.CreateNew) {
								_acc[_child.childStep._id] = [
									{
										_id: v4(),
										label: String(_child.selectedOption),
										columnType: _child.columnType,
									},
								]
							}
							return _acc
						},
						{},
					)
					return _changes
				},
				{},
			)

			for (const sourceTaskId of Object.keys(newChains)) {
				const projectId = projectTasks.find((_task) => _task._id === sourceTaskId)?.project_id
				if (!projectId || _isEmpty(newChains[sourceTaskId])) {
					continue
				}

				await dispatch(
					projectActions.completeTask({
						taskId: sourceTaskId,
						options: [],
						taskDbChains: newChains[sourceTaskId],
						projectId: projectId,
						instanceName: undefined,
						filterChildren: Object.keys(newChains[sourceTaskId]),
					}),
				)
			}
			promise?.resolve({ state: ConfirmationResolveType.Confirmed, dependencies: [] })
			handleClose()
		} catch (error) {
			showSnackbar('Error updating chains', { variant: 'error' })
		} finally {
			setIsLoading(false)
		}
	}, [promise, projectTasks])

	const handleCancel = useCallback(() => {
		promise?.resolve({ state: ConfirmationResolveType.Rejected, dependencies: [] })
		handleClose()
	}, [promise])

	// You could replace the Dialog with your library's version
	const ConfirmationDialog = useMemo(
		() => (
			<Dialog
				open={promise !== null}
				fullWidth
				PaperProps={{
					sx: { borderRadius: 2, p: 2 },
				}}
			>
				<Alert severity="warning">
					<AlertTitle>Warning! This will change the current scope</AlertTitle>
					{`Changing this value will affect active chains`}
				</Alert>
				<DialogTitle>How would you like to proceed?</DialogTitle>
				<DialogContent>
					{promise &&
						promise?.affectedDependencies.map(
							({ _id: sourceId, chainSourceId, dependencies }, sourceIndex) => (
								<Box
									key={sourceId}
									sx={{
										borderBottomWidth:
											sourceIndex !== promise.affectedDependencies.length - 1 ? 0.5 : 0,
										borderBottomStyle: 'solid',
										borderBottomColor: colors.neutral['40'],
										marginBottom: 2,
									}}
								>
									<DialogContentText>
										<Text fontSize={14}>
											<b>
												{projectTasks.find((_pTask) => _pTask._id === chainSourceId)?.name ?? ''}
											</b>
										</Text>
									</DialogContentText>
									{dependencies.map((_dep, index) => {
										return (
											<Box
												key={_dep._id}
												sx={{
													boxShadow: 'rgb(0 0 0 / 12%) 2px 4px 15px;',
													borderRadius: '20px 0px 20px 20px',
													py: 1,
													px: 2,
													mb: 1,
												}}
											>
												<DialogContentText>
													<Text fontSize={14}>{_dep.childStep.name}</Text>
												</DialogContentText>
												<RadioGroup
													value={
														promise?.affectedDependencies[sourceIndex].dependencies[index].action
													}
													onChange={(e, value) => {
														const newPromise = { ...promise }
														newPromise.affectedDependencies[sourceIndex].dependencies[
															index
														].action = value as ProceedOption
														setPromise(newPromise)
													}}
												>
													<FormControlLabel
														value={ProceedOption.AddToExisting}
														control={<Radio />}
														label={
															<Box>
																<Text fontSize={14}>
																	{'Add the selected row(s) to the existing chain'}
																</Text>
																{_dep.existingChain && <ChainList chain={_dep.existingChain} />}
															</Box>
														}
														disabled={!_dep.existingChain}
													/>
													<FormControlLabel
														value={ProceedOption.CreateNew}
														control={<Radio />}
														label={
															<Box>
																<Text fontSize={14}>{'Create a new chain'}</Text>
															</Box>
														}
													/>
												</RadioGroup>
											</Box>
										)
									})}
								</Box>
							),
						)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleConfirm()} isLoading={isLoading}>
						Confirm
					</Button>
					<Button onClick={handleCancel} variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		),
		[promise, projectTasks, isLoading],
	)

	return [ConfirmationDialog, confirm]
}

export default useConfirmCellChange
