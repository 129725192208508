import { V3ClientTypes } from '@cango-app/types'
import { createSelector } from '@reduxjs/toolkit'
import _values from 'lodash/values'
import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import { FilesSdk, V3BlueprintSdk, V3ProjectSdk } from '@cango-app/sdk'

import { constructProjectSectionsWithTasks } from 'src/helpers/chains'

import { RoleWithUsage, selectors as rolesSelectors } from '../roles'
import { RootState } from '../../types'

import { ProjectFilesState, TaskListType } from './types'

const getProjectsState: (state: RootState) => RootState['cangoProjectsV3'] = createSelector(
	(state: RootState) => state.cangoProjectsV3,
	(projectsState) => projectsState,
)

const getCards: (state: RootState) => V3BlueprintSdk.Card[] = createSelector(
	getProjectsState,
	({ projectCards }) => projectCards,
)

const getMenuChips = createSelector(getCards, (cards) =>
	cards.map(({ _id, name, active }) => ({ _id, name, active })),
)

const getProjects = createSelector(getProjectsState, ({ allProjects }) => allProjects || {})

const getProjectlist = createSelector(getProjects, (projects) => _values(projects))

const getProjectsListForSelect = createSelector(getProjectlist, (projectList) => {
	const filteredForSelect = _map(projectList, ({ _id, name }) => ({ _id, label: name }))
	return _orderBy(filteredForSelect, 'label', 'asc')
})

const getSelectedProject = createSelector(getProjectsState, ({ project }) => {
	return project
})

const getProjectTasks = createSelector(getProjectsState, ({ tasks }) => tasks)

const getTaskDescendants: (state: RootState, taskId?: string) => V3ClientTypes.Project.Task[] =
	createSelector(
		getProjectTasks,
		(state: RootState, taskId?: string) => taskId,
		(tasks, taskId) => {
			let offspring: string[] = []
			if (!taskId) return []

			const recursivelyGetChildren = (parentId: string) => {
				const parentTask = tasks.find(({ _id }) => _id === parentId)
				const children = tasks
					.filter(({ parent }) => {
						if (parentTask?.isMultiUse) return false
						// use step infrastructure to get children
						// if no step_id, use parent
						return parent?._id === parentId
					})
					.map(({ _id }) => _id)
				if (!children.length) return []
				offspring = [...offspring, ...children]
				children.map(recursivelyGetChildren)
			}

			recursivelyGetChildren(taskId)

			return tasks.filter(({ _id }) => offspring.includes(_id))
		},
	)

const getTasksWithDatabaseLogic = createSelector(getProjectTasks, (tasks) => {
	return tasks.filter((_task) =>
		_task.step?.descendants.some((_desc) => !!_desc.database_chain_logic),
	)
})

const getSelectedTask: (
	state: RootState,
	taskId?: string,
) => V3ClientTypes.Project.Task | undefined = createSelector(
	(state: RootState, taskId?: string) => taskId,
	getProjectTasks,
	(state: RootState) => state,
	(selectedTaskId, projectTasks, state) => {
		const task = projectTasks.find(({ _id }) => _id === selectedTaskId)
		if (!task) return
		const taskDescendants = getTaskDescendants(state, selectedTaskId).filter(
			({ parent, isMultiUse }) => !isMultiUse && parent?._id === selectedTaskId,
		)
		return {
			...task,
			isCompletable: taskDescendants.every(({ lifecycle }) => lifecycle.complete),
		}
	},
)

const getMappedProjectTasks: (state: RootState) => Map<string, V3ClientTypes.Project.Task> =
	createSelector(getProjectTasks, (projectTasks) => {
		return new Map(projectTasks.map((task) => [task._id, task]))
	})

const isChatModalOpen = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.isChatModalOpen,
)

export interface ProjectSection {
	_id: V3ClientTypes.Project.Task['_id']
	step_id?: string
	name: string
	tasks: V3ClientTypes.Project.Task[]
	chain: V3ClientTypes.Project.Task['chain']
	isBlocked: boolean
	projectId: string
}

const getProjectSectionsWithTasksSelector = (returnAllTasks?: boolean) =>
	createSelector(getProjectTasks, (tasks) =>
		constructProjectSectionsWithTasks(tasks, returnAllTasks),
	)

const getTask: (
	state: RootState,
	projectId: string,
	taskId?: string,
) => V3ClientTypes.Project.Task | undefined = createSelector(
	getMappedProjectTasks,
	getTaskDescendants,
	(_: RootState, __: string, taskId?: string) => taskId,
	(mappedTasks, taskDescendants, taskId) => {
		const task = mappedTasks.get(taskId || '')
		if (!task) return
		const taskChildren = taskDescendants.filter(({ isMultiUse }) => !isMultiUse)
		return { ...task, isCompletable: taskChildren.every(({ lifecycle }) => lifecycle.complete) }
	},
)

const getDriveId = createSelector(getSelectedProject, (project) => project?.google_drive_folder_id)

const getProjectFiles: (state: RootState) => FilesSdk.GetFilesByFolderIdResponse = createSelector(
	getProjectsState,
	({ projectFiles }) => projectFiles,
)

const getProjectFilesState: (state: RootState) => ProjectFilesState = createSelector(
	getProjectsState,
	({ projectFilesState }) => projectFilesState,
)

const getSelectedTaskRoles = createSelector(getSelectedTask, (task) => {
	if (!task) return []
	return task.actions.reduce((_acc: string[], _action) => {
		return _uniq([..._acc, ..._action.roles])
	}, [])
})

const getTaskRoles: (state: RootState) => { role: string; user?: string }[] = createSelector(
	getSelectedProject,
	rolesSelectors.getMappedRoles,
	getSelectedTaskRoles,
	(project, mappedRoles, selectedTaskRoles) => {
		if (!project) return []
		const projectRoles = project.roles
		const roles = selectedTaskRoles.map((_role) => mappedRoles.get(_role))
		const internalRoles = roles.filter((_role) => !!_role && _role.internal) as RoleWithUsage[]
		return internalRoles.map((actionRole) => {
			const matchedRole = projectRoles.find(({ role }) => role === actionRole._id)
			if (matchedRole) {
				return matchedRole
			}
			return {
				role: actionRole._id,
			}
		})
	},
)

const getTaskExternals: (state: RootState) => { role: string; contact?: string }[] = createSelector(
	getSelectedProject,
	rolesSelectors.getMappedRoles,
	getSelectedTaskRoles,
	(project, mappedRoles, selectedTaskRoles) => {
		if (!project) return []
		const projectExternals = project.externals
		const roles = selectedTaskRoles.map((_role) => mappedRoles.get(_role))
		const externalRoles = roles.filter((_role) => !!_role && !_role.internal) as RoleWithUsage[]
		return externalRoles.map((actionRole) => {
			const matchedExternal = projectExternals.find(({ role }) => role === actionRole._id)
			if (matchedExternal) {
				return {
					role: matchedExternal.role,
					contact: matchedExternal.contact,
				}
			}
			return {
				role: actionRole._id,
			}
		})
	},
)

const getProjectSection: (state: RootState, sectionId?: string) => ProjectSection | undefined =
	createSelector(
		getProjectSectionsWithTasksSelector(),
		(state: RootState, sectionId?: string) => sectionId,
		(sections, sectionId) => sections.find(({ _id }) => _id === sectionId),
	)

const getProjectsLoadingState = createSelector(
	getProjectsState,
	({ loadingProjectsState }) => loadingProjectsState,
)

const getProjectLoadingState = createSelector(
	getProjectsState,
	({ loadingProjectState }) => loadingProjectState,
)
const getProjectTasksState = createSelector(
	getProjectsState,
	({ projectTasksStates }) => projectTasksStates,
)

const getSectionTasksPendingUpload = createSelector(getProjectSection, (section) => {
	if (!section) {
		return []
	}

	return section.tasks.filter((_task) => {
		if (!_task?.actions) {
			return false
		}
		return _task.actions.some((_action) => _action.file_ids.includes(V3ProjectSdk.UPLOADING_TEXT))
	})
})

const getTaskListType: (state: RootState) => TaskListType = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.myTaskListType,
)

const getNumberOfFilesPendingUpload = createSelector(getSectionTasksPendingUpload, (tasks) => {
	return tasks.reduce((acc, task) => {
		const numberOfFileIds = task.actions.reduce((_acc: number, _action) => {
			const numberOfUploading = _action.file_ids.filter(
				(_id) => _id === V3ProjectSdk.UPLOADING_TEXT,
			).length
			return _acc + numberOfUploading
		}, 0)
		return acc + numberOfFileIds
	}, 0)
})

const getTotalNumberOfFilesRequiringUpload = createSelector(
	getProjectsState,
	(state) => state.filesToUpload,
)

const getPercentageOfTasksUploaded = createSelector(
	getNumberOfFilesPendingUpload,
	getTotalNumberOfFilesRequiringUpload,
	(filesPendingUpload, totalTasksRequiringUpload) => {
		if (totalTasksRequiringUpload === 0) {
			return 100
		}

		const effectiveCompleted = totalTasksRequiringUpload - filesPendingUpload * 0.5

		const percentage = (effectiveCompleted / totalTasksRequiringUpload) * 100
		return Math.min(100, Math.max(0, percentage))
	},
)

const getBlockOptions = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.blockOptions,
)

const getIsLoadingBlockOptions = createSelector(
	getProjectsState,
	(myTasksState) => myTasksState.isLoadingBlockOptions,
)

const getProjectWideChains = createSelector(getProjectsState, (state) => state.project_wide_chains)

const getProjectDatabaseCtas = createSelector(getProjectsState, (state) => state.database_ctas)

export const selectors = {
	getCards,
	getMenuChips,
	getSelectedProject,
	getProjectTasks,
	getTaskDescendants,
	getTask,
	getDriveId,
	getSelectedTask,
	getProjectFiles,
	getProjectFilesState,
	getTaskRoles,
	getTaskExternals,
	// getProjectSectionsWithMasterTasks,
	getProjectSectionsWithTasksSelector,
	getMappedProjectTasks,
	getProjectsListForSelect,
	getProjectsLoadingState,
	getProjectLoadingState,
	getProjectTasksState,
	getProjectlist,
	getProjectSection,
	getSectionTasksPendingUpload,
	getTaskListType,
	getPercentageOfTasksUploaded,
	getNumberOfFilesPendingUpload,
	getTotalNumberOfFilesRequiringUpload,
	getBlockOptions,
	getIsLoadingBlockOptions,
	isChatModalOpen,
	getProjectWideChains,
	getProjectDatabaseCtas,
	getTasksWithDatabaseLogic,
}
