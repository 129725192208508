import React, { ComponentType, useState, useMemo } from 'react'
import orderBy from 'lodash/orderBy'
import Skeleton from '@mui/material/Skeleton'
import { useNavigate } from 'react-router-dom'
import Grow from '@mui/material/Grow'
import { V3BlueprintSdk } from '@cango-app/sdk'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { RenderFor } from 'src/components/utils/Permissions'
import { Box } from 'src/components'
import { selectors as configSelectors } from 'src/store/modules/config'

import { Card } from './card'
import { CreationCard } from './creation-card'
import 'src/assets/css/Blueprint.scss'
import { MenuFilters, SortMethod } from './menu-filters'

export type CardMenuType = 'blueprint' | 'blueprintV3' | 'project'

type CardMenuProps = {
	cards: V3BlueprintSdk.Card[]
	forwardUrlRef: CardMenuType
	onCreateNewDocument: (
		selectedBlueprintId: string,
	) => Promise<{ requestStatus: 'fulfilled' | 'rejected'; _id?: string } | void>
	isLoading: boolean
}

const CardMenu: ComponentType<CardMenuProps> = ({
	cards,
	forwardUrlRef,
	onCreateNewDocument,
	isLoading,
}) => {
	const navigate = useNavigate()
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const [selectedSortMethod, setSelectedSortMethod] = useState<SortMethod>(
		SortMethod.DateCreatedNewestFirst,
	)
	const [filterByName, setFilterByName] = useState('')

	const goToDocument = (_id: string) => {
		navigate(`/${forwardUrlRef}/${_id}?orgId=${organisationId}`)
	}

	const handleSelectCard = async (_id: string) => {
		goToDocument(_id)
	}

	const handleCreateNewDocumentSuccess = (_id: string) => {
		goToDocument(_id)
	}

	const getLoadingSkeleton = () =>
		[...Array(6)].map((item) => (
			<Skeleton key={item} width={275} height={180} style={{ margin: 2 }} />
		))

	const filteredCards = useMemo(() => {
		const filteredCards = cards.filter((card) => {
			return card.name.toLowerCase().includes(filterByName.toLowerCase())
		})

		if (selectedSortMethod === SortMethod.DateCreatedNewestFirst) {
			return filteredCards.sort((_cardA, _cardB) =>
				dayjs(_cardB.createdAt).diff(dayjs(_cardA.createdAt)),
			)
		} else if (selectedSortMethod === SortMethod.DateCreatedOldestFirst) {
			return filteredCards.sort((_cardA, _cardB) =>
				dayjs(_cardA.createdAt).diff(dayjs(_cardB.createdAt)),
			)
		} else if (selectedSortMethod === SortMethod.AZ) {
			return orderBy(filteredCards, 'name', 'asc')
		} else if (selectedSortMethod === SortMethod.ZA) {
			return orderBy(filteredCards, 'name', 'desc')
		}

		return filteredCards
	}, [cards, filterByName, selectedSortMethod])

	return (
		<>
			<MenuFilters
				onSortMethodChange={setSelectedSortMethod}
				selectedSortMethod={selectedSortMethod}
				onFilterByNameChange={setFilterByName}
				filterByName={filterByName}
			/>
			<Box p="24px" display="flex" flexWrap="wrap">
				{isLoading ? (
					getLoadingSkeleton()
				) : (
					<>
						<Grow style={{ transformOrigin: 'left' }} timeout={300}>
							<RenderFor permissions={['consultant']}>
								<CreationCard
									menuType={forwardUrlRef}
									onSubmit={onCreateNewDocument}
									onSubmitSuccess={handleCreateNewDocumentSuccess}
								/>
							</RenderFor>
						</Grow>
						{filteredCards.map((card, index) => (
							<Grow key={card._id} in style={{ transformOrigin: 'left' }} timeout={300}>
								<div>
									<Card
										{...card}
										onSelectBlueprint={() => handleSelectCard(card._id)}
										menuType={forwardUrlRef}
									/>
								</div>
							</Grow>
						))}
					</>
				)}
			</Box>
		</>
	)
}

export default CardMenu
