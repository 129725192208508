import { TableTypes } from '@cango-app/types'
import { GridColType } from '@mui/x-data-grid-premium'

export const getFieldType = (type: TableTypes.FieldType): GridColType => {
	switch (type) {
		case TableTypes.FieldType.NUMBER:
		case TableTypes.FieldType.CALCULATION:
		case TableTypes.FieldType.RESOURCES:
		case TableTypes.FieldType.UNIQUE_ID:
			return 'number'
		case TableTypes.FieldType.DATE:
			return 'date'
		case TableTypes.FieldType.CONTACT:
		case TableTypes.FieldType.TABLE_SELECT:
		case TableTypes.FieldType.ROLE:
			return 'singleSelect'
		case TableTypes.FieldType.URL:
		case TableTypes.FieldType.VIDEO:
		case TableTypes.FieldType.CONCATINATION:
			return 'string'
		case TableTypes.FieldType.REFERENCE:
			return 'custom'
		default:
			return type
	}
}
