import React, { ComponentType, useCallback, useContext } from 'react'
import {
	DataGridPremium,
	DataGridPremiumProps,
	GridCellParams,
	GridSlotsComponentsProps,
	GridToolbarContainer,
} from '@mui/x-data-grid-premium'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { TableTypes } from '@cango-app/types'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'

import { useColumnFormatter } from '../../modules/tables/use-column-formatter'
import { getRowDifference } from '../../modules/tables/core-table'
import { TableContext } from '../../providers/table-provider'
import { dataGridTables } from '../../helpers/ui'
import { ActionsButton } from '../../modules/tables/actions-button'

import { useRows } from './use-rows'
import { processUpdatedAggregatedRow } from './process-updated-aggregated-row'
import { getGroupId } from './get-group-id'

type DisplayViewProps = TableTypes.TableView & {
	initialState?: GridInitialStatePremium
	datagridProps?: Partial<DataGridPremiumProps>
}

const DisplayViewToolbar: ComponentType<NonNullable<GridSlotsComponentsProps['toolbar']>> = (
	props,
) => {
	return (
		<GridToolbarContainer>
			{props.permittedActions?.length && (
				<ActionsButton permittedFunctions={props.permittedActions} />
			)}
		</GridToolbarContainer>
	)
}

export const DisplayView: ComponentType<DisplayViewProps> = (props) => {
	const {
		aggregations,
		subRowColumns: subRowColumnVisibility,
		columns: columnVisibility,
		displayChildren: shouldShowChildren,
		groupedField,
		initialState,
		datagridProps,
		withVerticalTableBorder,
		actions,
	} = props
	const { cacheMultipleRowUpdates, apiRef, isUpdatingTable, saveChanges, mappedColumns } =
		useContext(TableContext)
	const { rows, subRows } = useRows(aggregations, shouldShowChildren, groupedField)

	const { columns } = useColumnFormatter({
		apiRef,
		isBulkEditEnabled: false,
		isTableLocked: true,
		showIcons: false,
		sortingModel: [],
	})

	const processRowUpdate = useCallback(
		async (newRow: any, oldRow: any) => {
			const subs = subRows[newRow._id]
			const rowDifference = getRowDifference(oldRow, newRow)
			const subRowPayload = subs.map((subRow) => ({
				oldRow: subRow,
				newRow: {
					...subRow,
					...rowDifference.reduce((updatedFields: { [fieldId: string]: any }, fieldId) => {
						updatedFields[fieldId] = newRow[fieldId]
						return updatedFields
					}, {}),
				},
			}))
			const cachedRows = await cacheMultipleRowUpdates(subRowPayload, true)
			await saveChanges()

			return processUpdatedAggregatedRow({
				groupId: getGroupId(groupedField, newRow[groupedField]),
				groupName: newRow[groupedField],
				subRows: cachedRows,
				aggregations,
				groupColumn: groupedField,
				mappedColumns,
			})
		},
		[subRows],
	)

	const handleCellClick = async (params: GridCellParams) => {
		if (params.colDef.type === 'boolean') {
			if (isUpdatingTable) {
				return
			}
			const newValue = !params.value
			const cachedUpdate = await processRowUpdate(
				{ ...params.row, [params.field]: newValue },
				params.row,
			)
			if (cachedUpdate) {
				apiRef.current.updateRows([{ id: params.row._id, ...cachedUpdate }])
			}
			return
		}
	}

	return (
		<Stack direction="column" spacing={1}>
			<DataGridPremium
				apiRef={apiRef}
				initialState={initialState}
				columns={columns.map((_col) => ({
					..._col,
					editable: aggregations?.[_col.field] === 'value',
				}))}
				columnVisibilityModel={{
					...columnVisibility,
					__detail_panel_toggle__: shouldShowChildren && !!groupedField,
				}}
				rows={rows}
				getRowId={(row) => row._id}
				disableColumnMenu
				disableColumnFilter
				checkboxSelection={!!actions?.length}
				showColumnVerticalBorder={withVerticalTableBorder}
				showCellVerticalBorder={withVerticalTableBorder}
				processRowUpdate={processRowUpdate}
				onCellClick={handleCellClick}
				sx={dataGridTables}
				disableMultipleRowSelection={!!props.groupedField}
				disableColumnSelector
				getCellClassName={(params: GridCellParams) => {
					if (params.colDef.type === 'boolean') {
						return 'cell-checkbox'
					}
					return ''
				}}
				slots={{
					toolbar: DisplayViewToolbar,
				}}
				slotProps={{
					toolbar: {
						permittedActions: actions,
					},
				}}
				getDetailPanelContent={(row) => {
					const rowSubRows = subRows[row.id] ?? []

					if (!shouldShowChildren || !rowSubRows.length) {
						return null
					}

					return (
						<Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
							<Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
								<DataGridPremium
									disableColumnMenu
									disableColumnFilter
									getRowId={(row) => row._id}
									density="compact"
									columns={columns}
									columnVisibilityModel={{ ...subRowColumnVisibility, [groupedField]: false }}
									rows={rowSubRows}
									sx={{ flex: 1 }}
									hideFooter
									autosizeOnMount
									showColumnVerticalBorder
									showCellVerticalBorder
								/>
							</Paper>
						</Stack>
					)
				}}
				{...datagridProps}
			/>
		</Stack>
	)
}
