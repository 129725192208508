import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { useContext, useMemo } from 'react'
import { v4 } from 'uuid'
import { Stack } from '@mui/material'

import { TableContext } from 'src/providers/table-provider'
import { TrashIcon } from 'src/assets/icons'

import { Select } from '../select'
import { Box } from '../box'
import { Button } from '../button'
import { Text } from '../text'
import { Grid } from '../grid'
import { IconButton } from '../icon-button'
import { Divider } from '../divider'

export const GroupingChildren = () => {
	const { control, watch } = useFormContext<TableTypes.TableView>()
	const { mappedColumns } = useContext(TableContext)

	const { fields, append, remove } = useFieldArray({ control, name: 'groupedFields' })

	const columnList = useMemo(() => {
		return [...mappedColumns.keys()].reduce((_acc: { _id: string; label: string }[], _fieldId) => {
			_acc.push({
				_id: _fieldId,
				label: mappedColumns.get(_fieldId)?.name ?? '',
			})
			return _acc
		}, [])
	}, [mappedColumns])

	const groupedFields = watch('groupedFields')

	return (
		<Box>
			<Stack direction="column" spacing={1} paddingBottom={2}>
				{fields.map((column, index) => {
					return (
						<>
							<Grid container key={column.id} spacing={2} paddingBottom={2}>
								<Grid item xs={12}>
									<Text variant="h6">{`Grouping ${index + 1}`}</Text>
								</Grid>
								<Grid item xs={5}>
									<Controller
										key={column.id}
										control={control}
										name={`groupedFields.${index}.groupColumnId`}
										render={({ field: { value, onChange } }) => (
											<Select
												fullWidth
												label="Group by column"
												name="grouping"
												options={columnList}
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<Controller
										key={column.id}
										control={control}
										name={`groupedFields.${index}.visibilityModel`}
										render={({ field: { value, onChange } }) => {
											const values = columnList.reduce((_selectedValues: string[], _field) => {
												if (value?.[_field._id] || value?.[_field._id] === undefined) {
													_selectedValues.push(_field._id)
												}
												return _selectedValues
											}, [])

											const handleSelectChange = (values: string[]) => {
												const newColumnVisibility = columnList.reduce(
													(_acc: GridColumnVisibilityModel, _column) => {
														_acc[_column._id] = values.includes(_column._id)
														return _acc
													},
													{},
												)
												onChange(newColumnVisibility)
											}
											return (
												<Box>
													<Select
														fullWidth
														multiple
														label="Visibility in group"
														name="visibility"
														options={columnList}
														value={values}
														size="small"
														onChange={(e) => handleSelectChange(e.target.value as string[])}
													/>
													<Button
														size="small"
														variant="text"
														onClick={() => handleSelectChange([])}
														sx={{ minWidth: 75 }}
													>
														Clear all
													</Button>
												</Box>
											)
										}}
									/>
								</Grid>
								<Grid item xs={1}>
									<Box mt={3}>
										<IconButton onClick={() => remove(index)}>
											<TrashIcon width={24} />
										</IconButton>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Text>Aggregations:</Text>
									<Controller
										control={control}
										name={`groupedFields.${index}.aggregationModel`}
										render={({ field: { value, onChange } }) => {
											return (
												<Stack direction="row" width="100%" flexWrap="wrap">
													{columnList
														.filter(({ _id }) => groupedFields[index].visibilityModel[_id])
														.map(({ _id, label }) => {
															const columnType =
																mappedColumns.get(_id)?.type ?? TableTypes.FieldType['STRING']
															const options = [
																TableTypes.FieldType['NUMBER'],
																TableTypes.FieldType['CALCULATION'],
															].includes(columnType)
																? TableTypes.AggregationFormulaNumbers
																: TableTypes.AggregationFormulaStrings
															return (
																<Select
																	containerProps={{
																		minWidth: '150px',
																		marginRight: 2,
																	}}
																	key={`${_id}/${index}`}
																	fullWidth
																	withNoneOption
																	specialOption={TableTypes.AggregationSpecialCase['show']}
																	label={label}
																	value={value?.[_id]}
																	onChange={(event) => {
																		const newValue = {
																			...value,
																			[_id]: event.target.value,
																		}
																		onChange(newValue)
																	}}
																	options={Object.keys(options).map((agg) => ({
																		_id: agg,
																		label: agg,
																	}))}
																/>
															)
														})}
												</Stack>
											)
										}}
									/>
								</Grid>
							</Grid>
							<Divider key={column.id} />
						</>
					)
				})}
			</Stack>
			<Button
				disabled={fields.length === columnList.length}
				onClick={() => {
					append({
						_id: v4(),
						groupColumnId: columnList[0]?._id ?? '',
						visibilityModel: Object.fromEntries(columnList.map(({ _id }) => [_id, true])),
						aggregationModel: {},
					})
				}}
			>
				Add nested group
			</Button>
		</Box>
	)
}
