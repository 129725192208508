import { ComponentType, useContext, useMemo, memo } from 'react'
import { V3ClientTypes, V3ProjectTypes, TableTypes, V3BlueprintTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'

import { colors } from 'src/theme/colors'
import { selectors as contactSelectors } from 'src/store/modules/contacts'

import { Box } from '../box'
import { Chip } from '../chip'
import { Text } from '../text'
import { TableContext } from '../../providers/table-provider'

const ChainPrefix: ComponentType<{
	prefix: string | undefined
	hasSelectedOption: boolean
}> = memo(function ChainPrefix({ prefix: defaultPrefix, hasSelectedOption }) {
	const { mappedColumns } = useContext(TableContext)

	const prefix = useMemo(() => {
		if (!defaultPrefix) {
			return ''
		}

		const column = mappedColumns.get(defaultPrefix)

		if (!column) {
			return defaultPrefix
		}

		return column.name
	}, [defaultPrefix, mappedColumns])

	return <>{`${prefix}${hasSelectedOption ? ' : ' : ''}`}</>
})

const ChainSelectedOption: ComponentType<{
	value: string | undefined
	columnType: TableTypes.FieldType | undefined
	prefix: string | undefined
}> = memo(function ChainSelectedOption({ value, columnType, prefix }) {
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const { mappedColumns } = useContext(TableContext)

	const option = useMemo(() => {
		if (!value) {
			return null
		}

		let selectedOption = value

		if (columnType === TableTypes.FieldType.CONTACT) {
			const contact = mappedContacts.get(value)
			if (contact) {
				selectedOption = `${contact.name} ${contact.surname}`
			}
		}

		const columnValueOptions = mappedColumns.get(prefix ?? '')?.valueOptions
		const isValueOption = columnValueOptions?.find((option) => option._id === value)
		if (isValueOption) {
			selectedOption = isValueOption.label
		}

		if (!columnType || !prefix) {
			return selectedOption
		}

		const column = mappedColumns.get(prefix)

		if (!column || !column.valueOptions?.length) {
			return selectedOption
		}

		const foundValue = column.valueOptions.find((_option) => _option._id === value)

		if (
			[
				TableTypes.FieldType.SINGLE_SELECT,
				TableTypes.FieldType.REFERENCE,
				TableTypes.FieldType.TABLE_SELECT,
			].includes(columnType) &&
			foundValue
		) {
			selectedOption = foundValue.label
		}

		return selectedOption
	}, [value, mappedContacts, mappedColumns])

	return <>{option}</>
})

export const ChainChip: ComponentType<{
	prefix: string
	selected_option: string | undefined
	isLoadingTable: boolean
	columnType?: TableTypes.FieldType | undefined
	color?: string
}> = ({ isLoadingTable, prefix, selected_option, columnType, color }) => (
	<Chip
		label={
			isLoadingTable ? (
				<PulseLoader size={3} />
			) : (
				<Text fontSize={12} color={colors.neutral['80']}>
					<ChainPrefix prefix={prefix} hasSelectedOption={!!selected_option} />
					<b>
						<ChainSelectedOption value={selected_option} columnType={columnType} prefix={prefix} />
					</b>
				</Text>
			)
		}
		sx={{ backgroundColor: color, fontSize: 12, color: '#fff', mr: 0.5, mb: 0.5 }}
		size="small"
	/>
)

export const ChainList: ComponentType<{
	chain: V3ClientTypes.Project.Task['chain']
}> = ({ chain }) => {
	const { isLoadingTable } = useContext(TableContext)
	const threadList = useMemo(() => {
		const parentThreads =
			chain?.parent_chain_tasks?.reduce((_acc: V3ProjectTypes.ProjectChain['label'][], _parent) => {
				if (!_parent.chain?.label) {
					return _acc
				}
				return [..._acc, _parent.chain.label]
			}, []) ?? []

		if (!chain?.label) {
			return parentThreads
		}

		return [...parentThreads, chain.label]
	}, [chain])

	return (
		<Box>
			{threadList.map(({ prefix, selected_option, color, columnType }) => {
				if (!prefix) {
					return null
				}
				return (
					<ChainChip
						key={`${prefix}-${selected_option}`}
						prefix={prefix}
						selected_option={selected_option}
						columnType={columnType}
						color={color}
						isLoadingTable={isLoadingTable}
					/>
				)
			})}
		</Box>
	)
}

export const ThreadList: ComponentType<{
	threads: V3BlueprintTypes.Thread[]
}> = ({ threads }) => {
	const { isLoadingTable } = useContext(TableContext)
	return (
		<Box>
			{threads.map(({ prefix, color }, index) => {
				if (!prefix) {
					return null
				}
				return (
					<Chip
						key={`${prefix}-${index}`}
						label={
							isLoadingTable ? (
								<PulseLoader size={3} />
							) : (
								<ChainPrefix prefix={prefix} hasSelectedOption={false} />
							)
						}
						sx={{ backgroundColor: color, fontSize: 12, mr: 0.5, mb: 0.5 }}
						size="small"
					/>
				)
			})}
		</Box>
	)
}
