import { TableTypes } from '@cango-app/types'

export const getDefaultValues = ({
	tableViews,
	viewId,
}: {
	tableViews: TableTypes.TableView[]
	viewId: string | undefined
}): Omit<TableTypes.TableView, '_id'> => {
	const defaultVal: Omit<TableTypes.TableView, '_id'> = {
		aggregations: {},
		columns: {},
		subRowColumns: {},
		displayChildren: true,
		groupedField: '',
		filters: {
			items: [],
		},
		name: '',
		displayMode: TableTypes.ViewStyleMode.editable,
		withVerticalTableBorder: true,
		withHorizontalTableBorder: true,
		groupedFields: [],
		actions: [],
	}
	if (!tableViews.length || !viewId) {
		return defaultVal
	}

	const foundView = tableViews.find((_view) => _view._id === viewId)

	if (!foundView) {
		return defaultVal
	}

	return {
		name: foundView.name,
		aggregations: foundView.aggregations ?? {},
		columns: foundView.columns ?? {},
		subRowColumns: foundView.subRowColumns ?? {},
		displayChildren: foundView.displayChildren ?? true,
		filters: foundView.filters ?? {
			items: [],
		},
		groupedField: foundView.groupedField ?? '',
		displayMode: foundView.displayMode ?? TableTypes.ViewStyleMode.editable,
		withVerticalTableBorder: foundView?.withVerticalTableBorder ?? true,
		withHorizontalTableBorder: foundView?.withHorizontalTableBorder ?? true,
		groupedFields: foundView?.groupedFields ?? [],
		actions: foundView?.actions ?? [],
	}
}
